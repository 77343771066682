<template>
  <div>
    <div style="padding:10px;position:relative">
      <svg @click="toggle()"
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-menu-2"
        width="28"
        height="28"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#ffffff"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M4 6l16 0" />
        <path d="M4 12l16 0" />
        <path d="M4 18l16 0" />
      </svg>
    </div>

    <van-tabbar v-if="showContent" v-model="active" active-color="#deceb7" :border="true" inactive-color="#979799">
      <van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
        <span>{{ k.title }}</span>
        <template #icon="props">
          <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" v-show="key !== 2 && key !== 5" />
          <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" v-show="key === 2" />
          <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" style="padding:6px" v-show="key === 5" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showContent: false,
      show: false,
      active: 0,
      item: [
        {
          router: "/Home",
          title: "Trang chủ",
          icon: {
            active: "/img/footer/ic-home-act.jpg",
            noactive: "/img/footer/ic-home.jpg",
          },
        },
        {
          router: "/Game",
          title: "Sảnh bình chọn",
          icon: {
            active: "/img/footer/ic-game-act.jpg",
            noactive: "/img/footer/ic-game.jpg",
          },
        },
        {
          router: "/Choose",
          title: "",
          icon: {
            active: "/img/footer/beauty.png",
            noactive: "/img/footer/beauty.png",
          },
        },
        {
          router: "/Video",
          title: "Rạp chiếu phim",
          icon: {
            active: "/img/footer/ic-film-act.jpg",
            noactive: "/img/footer/ic-film.jpg",
          },
        },
        {
          router: "/Mine",
          title: "Hồ sơ",
          icon: {
            active: "/img/footer/ic-user-act.jpg",
            noactive: "/img/footer/ic-user.jpg",
          },
        },
        {
          router: "/ServiceOnline",
          title: "CSKH",
          icon: {
            active: "/img/footer/ic-cskh-act.png",
            noactive: "/img/footer/ic-cskh.png",
          }
        }
      ],
    };
  },
  methods: {
    toggle() {
      this.showContent = !this.showContent;
    },
  },
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 75px;
@tabbar-img: 45px;
.van-tabbar {
  height: @tabbar-height;
  max-width: 600px;
  //left: 50%;
  //transform: translateX(-50%);
  background: #262626;
  top: 50px;
  left: 2px;
  position: absolute;
  z-index: 9999;
  bottom: auto;
  flex-direction: column;
  height: auto !important;;
  width: 150px;
  border: 2px solid #fff;
  border-radius: 10px;
  overflow:hidden;
}
::v-deep .van-tabbar-item--active {
  background: #262626;
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 15px;
  padding: 15px 0;
}
::v-deep .van-tabbar-item__text {
  white-space: nowrap;
}
.tui {
  width: 55px;
  height: 55px !important;
  //margin-top: -38.333px;
  background-color: white;
  border-radius: 50%;
  border: 5px solid white;
  z-index: 10;
}
[class*="van-hairline"]::after {
  border: none !important;
}
@media (max-width: 500px) {
  .van-tabbar-item__icon img {
    height: 33px;
    width: auto;
  }
  .van-tabbar-item {
    font-size: 12px;
  }
  .van-tabbar {
    width: 120px;
  }
}
</style>
